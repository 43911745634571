<template>
    <b-modal id="modal-addProduct" size="xl" ok-only>
        <div class="container-fluid add-form-list">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header d-flex justify-content-between">
                            <div class="header-title">
                                <h4 class="card-title">Add Product</h4>
                            </div>
                        </div>
                        <div class="card-body">
                            <form>
                                <div class="row">
                                    <div class="col-md-12">
                                        <p><b style="color:red">1688을 통해 구매하지 않거나, 별도 재고로 관리하는 사입제품의 경우, 구매링크를 '사입'이라고 표시해 주세요</b></p>
                                        <p>신규상품, 발주상태 등 상품 내역에 변경사항이 있을 경우 서플라이어에 접속한 후 <u v-b-modal.modal-3>크롬확장프로그램</u>으로 SKU 리스트를 먼저 수집해 주세요</p>
                                        <p>1. 현재 상품DB 다운로드 → <u>엑셀 파일에서 상품DB 업데이트</u> → 2. 업데이트된 상품DB 업로드 순으로 진행해 주세요.</p>
                                        <b-modal id="modal-3" size="xl" title="SKU 리스트 수집 방법" ok-only>
                                            <p><a href="https://chrome.google.com/webstore/detail/couplus/bogokmehpkdjafnlhjhinfgmeljdgmgh?hl=ko&authuser=0" target="_blank"><u>구글웹스토어</u></a>에 접속해서 "COUPLUS"를 다운받은 후 <b style="color:red"><u>"Supplier"에 로그인한 상태에서</u></b> SKU 리스트 수집 버튼을 클릭해주세요. 수집이 완료 될때까지 창을 닫거나 조정하시면 안됩니다.</p>
                                            <img  style="max-width:100%;" :src="require('../../../assets/images/small/extension_skulist.png')">
                                        </b-modal>
                                        
                                    </div>
                                    <!-- <div class="col-md-12 mt-3">
                                        <div class="form-group">
                                            <label  for="Code">1.&nbsp;&nbsp;SKU list 업로드 (신규상품 등록시 필수)</label>
                                            <b-button v-b-modal.modal-3 variant="link" class="ml-1"><u>SKU list 업로드 방법</u></b-button>
                                            <b-modal id="modal-3" size="xl" title="SKU list 다운로드 방법" ok-title="Save Changes" cancel-title="Close">
                                            <p><a href="https://supplier.coupang.com" target="_blank"><u>Supplier</u></a>에 접속해서 아래의 순서로 엑셀을 다운받은 후 업로드해주세요</p>
                                            <img  style="max-width:100%;" :src="require('../../../assets/images/small/sku정보download.jpg')">
                                            
                                            </b-modal>
                                            <div class="custom-file">
                                            <input ref="skuupload" type="file" @change="readskuFile" class="custom-file-input" id="inputGroupFile01">
                                            <label class="custom-file-label" for="inputGroupFile01">{{skuuploadstatus}}</label>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="col-md-12 mt-4">
                                        <div class="form-group">
                                            <label v-b-tooltip.bottom="'구매링크:1688구매링크, 옵션1_중국어, 옵션2_중국어 항목은 구매진행이 필요한 경우 필수요소입니다. 사입제품의 경우 구매링크에 사입으로 기입해주세요'">1. 현재 상품DB 다운로드</label>
                                            <div>
                                            <button type="reset" @click="excelDownFunc" class="btn btn-primary mr-2">Download</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label  for="Code">2. 업데이트된 상품DB 업로드</label>
                                            <div class="custom-file">
                                            <input ref="pdbupload" type="file" @change="readdbFile" class="custom-file-input" id="inputGroupFile02">
                                            <label class="custom-file-label" for="inputGroupFile02">{{pdbuploadstatus}}</label>
                                            </div>
                                            <div class="col-md-12" v-if="showspinner">
                                                <div>
                                                    <img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
                                                </div>
                                            </div>
                                        </div>
                                    </div>                            
                                </div>                            
                                <!-- <button @click="submit" type="submit" class="btn btn-primary mr-2">Update</button> -->
                                <!-- <button type="reset" class="btn btn-danger">Reset</button> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Page end  -->
        </div>  
    </b-modal>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required ,integer	} from 'vuelidate/lib/validators'
import * as XLSX from 'xlsx';
import axios from 'axios';

export default {
    name:'addProduct',
    mixins: [validationMixin],
    validations: {
      name: { required },
      Code: { required },
      Cost: { required,integer },
      Price:{ required,integer },
      Quantity:{required ,integer},
    },
    mounted () {
       
    },
    data(){
        return{   

            showspinner: false,
            
            skuuploadstatus: 'excel upload',
            pdbuploadstatus: 'excel upload',
            name: '',
            Code: '',
            Cost: '',
            Price:'',
            Quantity:'',
            selectValue: '',
            option1: [
                {value: 1, text: 'Standard'},
                {value: 2, text: 'Combo'},
                {value: 3, text: 'Digital'},
                {value: 4, text: 'Service'},
            ],
            option2: [
                {value: 1, text: 'CREM01'},
                {value: 2, text: 'UM01'},
                {value: 3, text: 'SEM01'},
                {value: 4, text: 'COF01'},
                {value: 5, text: 'FUN01'},
                {value: 6, text: 'DIS01'},
                {value: 7, text: 'NIS01'},
            ],
              option3: [
                {value: 1, text: 'Beauty'},
                {value: 2, text: 'Grocery'},
                {value: 3, text: 'Food'},
                {value: 4, text: 'Furniture'},
                {value: 5, text: 'Shoes'},
                {value: 6, text: 'Frames'},
                {value: 7, text: 'Jewellery'},
            ],
            option4: [
                {value: 1, text: 'Exclusive'},
                {value: 2, text: 'Inclusive'},
            ],
        }
    },
    methods: {
        readskuFile(event) {
            
            const file = event.target.files[0];
            var filename = file.name;
            let reader = new FileReader();
            var content_skuuploadstatus
            reader.onload = (e) => {
                // let data = reader.result;
                let data = e.target.result;
                let workbook = XLSX.read(data, {type: 'binary'});
                workbook.SheetNames.forEach(sheetName => {
                    const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                    console.log(roa)
                    roa.forEach((element) => {
                        element["SKU ID"] = parseInt(element["SKU ID"])
                       element.companyid = this.$store.state.user.companyid
                    });
                    console.log(roa)
                    axios.post('/api/addproduct/addskulist',roa)
                    .then((res) => {
                        console.log(res.data)
                        // if (res.data.등록수 == '0') {
                        //     var no_reg_alarm = "이미 모든 상품이 업데이트되어 있습니다";
                        //     content_skuuploadstatus = filename + " 업데이트완료 (" + res.data.등록수 + "/" + res.data.등록요청수 + ")";
                        //     this.skuuploadstatus = content_skuuploadstatus;
                        //     this.$store.commit('productdbupdate', res.data.productdb);
                        //     alert(no_reg_alarm);
                        // } else {
                            var reg_alarm = "신규등록 상품수 : "  + res.data.등록수 + ", 전체 상품수 : " + res.data.productdb.length;
                            content_skuuploadstatus = filename + " 업데이트완료 (" + res.data.등록수 + "/" + res.data.등록요청수 + ")";
                            this.skuuploadstatus = content_skuuploadstatus;
                            this.$store.commit('productdbupdate', res.data.productdb);
                            alert(reg_alarm);
                        // }
                        
                    })
                    .catch(console.log);
                });
                
            };
            reader.readAsBinaryString(file);
            this.$refs.skuupload.value = '';
            this.skuuploadstatus = content_skuuploadstatus;
        },
        compareobject(a,b){
            function sort (o) {
                var result = {};

                if (typeof o !== "object") {
                    return o;
                }
                
                // console.log(o)
                // console.log(Object.keys(o))
                Object.keys(o).sort().forEach(function (key) {
                    if(o[key]){
                        result[key] = sort(o[key]);
                    } else {
                        result[key] = null
                    }
                });

                return result;
            }
            
            return JSON.stringify(sort(a)) === JSON.stringify(sort(b))
        },
        async readdbFile(event) {
            this.showspinner = true;
            this.pdbuploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
            
            // console.log(this.$store.state)
            // var test = this.$store.state.productdb.find(ele => ele["SKU ID"] == 17690610)
            // console.log(JSON.stringify(test))
            setTimeout(async () => {
                const file = event.target.files[0];
                // var filename = file.name;
                let reader = new FileReader();
                this.showspinner = true;
                reader.onload = async (e) => {
                    // let data = reader.result;
                    let data = e.target.result;
                    let workbook = XLSX.read(data, {type: 'binary'});
                    workbook.SheetNames.forEach( async (sheetName) => {
                        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log(roa)
                        console.log(this.$store.state.user.companyid)
                        this.showspinner = true;
                        this.pdbuploadstatus = "업로드 중 ...... (업로드가 완료될 때까지 기다려 주세요)";
                        this.showspinner = true;
                        var updatelist = [];
                        var deletelist = [];
                        if(roa && roa.length > 0){
                            roa.forEach(e => {
                                // console.log(e)
                                if(!this.$store.state.productdb){
                                    updatelist.push(e)
                                } else {
                                    if(!this.compareobject(e,this.$store.state.productdb.find(ele => ele["SKU ID"] == e["SKU ID"]))){
                                        updatelist.push(e)
                                    }
                                }
                            });
                        }
                        if(this.$store.state.productdb && this.$store.state.productdb.length > 0){
                            this.$store.state.productdb.forEach(e => {
                                if(!roa){
                                    deletelist.push(e)
                                } else {
                                    if(!this.compareobject(e,roa.find(ele => ele["SKU ID"] == e["SKU ID"]))){
                                        deletelist.push(e)
                                    }
                                }
                            })
                        }
                        if(updatelist && updatelist.length > 0){
                            updatelist.forEach(e => {
                                e["SKU ID"] = parseInt(e["SKU ID"]);
                                delete e._id
                            })
                        }
                        console.log(deletelist)
                        console.log(updatelist)
                        if (roa[0].companyid == this.$store.state.user.companyid && confirm('삭제할 상품DB수 : ' + deletelist.length + '개' + '\n' + '업데이트할 상품DB수 : ' + updatelist.length + '개' + '\n' + '위와 같이 상품DB를 업데이트 하시겠습니까?')) {
                            if(deletelist.length > 0){
                                var res = await axios.post('/api/addproduct/deletedb',deletelist).catch((e) => {alert('업로드실패(238)');console.log(e);this.showspinner = false;});
                                if (res.data && res.data.요청결과 == "삭제완료") {
                                    var res1 = await axios.post('/api/addproduct/updatedb',updatelist).catch((e) => {alert('업로드실패(196)');console.log(e);this.showspinner = false;});
                                    console.log(res1.data)
                                    if (res1.data && res1.data.요청결과 == "업로드완료") {
                                        this.$store.commit('productdbupdate',res1.data.productdb)
                                        this.pdbuploadstatus = "excel upload";
                                        alert("전체 : " + res1.data.productdb.length + "개, 신규 : " + res1.data.등록수 + "개, 변경 : " + res1.data.업데이트 + "개, 삭제 : " + res.data.deletedCount);
                                        this.showspinner = false;
                                    } else {
                                        alert("업로드실패(189)");
                                        this.showspinner = false;
                                    }
                                } else {
                                    alert("업로드실패(234)");
                                    this.showspinner = false;
                                }
                            } else {
                                if(updatelist.length > 0){
                                    res1 = await axios.post('/api/addproduct/updatedb',updatelist).catch((e) => {alert('업로드실패(196)');console.log(e);this.showspinner = false;});
                                    console.log(res1.data)
                                    if (res1.data && res1.data.요청결과 == "업로드완료") {
                                        this.$store.commit('productdbupdate',res1.data.productdb)
                                        this.pdbuploadstatus = "excel upload";
                                        alert("전체 : " + res1.data.productdb.length + "개, 신규 : " + res1.data.등록수 + "개, 변경 : " + res1.data.업데이트 + "개, 삭제 : 0개");
                                        this.showspinner = false;
                                    } else {
                                        alert("업로드실패(189)");
                                        this.showspinner = false;
                                    }
                                } else {
                                    alert('업데이트할 항목이 없습니다.')
                                    this.showspinner = false;
                                }
                            }
                            
                        } else {
                            alert("Company ID가 일치하지 않습니다")
                        }
                    });
                };
                reader.readAsBinaryString(file);
                this.$refs.pdbupload.value = '';
            }, 10);
        },
        excelDownFunc(){
            var arr = JSON.parse(JSON.stringify(this.$store.state.productdb))
            console.log(arr)
            arr.forEach(e => {
                if(e._id){delete e._id}
                if(e.skudetail){delete e.skudetail}
            })
            const myHeader = ["SKU ID","요청","상품명","바코드","발주가능상태","담당 BM","발주담당자","길이 (mm)","넓이 (mm)","높이 (mm)","최소구매수량 (MOQ)","중량 (g)","Box 바코드","Inner Case 내 SKU 수량","Box 내 총수량","Pallet 1단의 SKU 수량","Pallet 내 SKU 수량","구매링크","옵션1_한국어","옵션2_한국어","옵션1_중국어","옵션2_중국어","제품분류","등록이미지","HS-CODE","판매구성수량","총구매단가","매입가","현재매입가","구매가능상태","신고영문명","신고재질","신고가격(USD)",'합포장가능여부(y/n)','수입원/판매원','제조원','제조국','내용량','원료명및성분명(재질)','상품유형','사용시주의사항','사용기준','입고리드타임'];
            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});

            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);

            var wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, 'productDB');
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, 'productDB.xlsx');
        }    
    }
}
</script>